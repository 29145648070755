@tailwind base;
@tailwind components;
@tailwind utilities;


.bg-gradient{
    background: linear-gradient(135deg, #003049 8.26%, #D62828 31.67%, #F77F00 55.09%, #FCBF49 78.02%, #EAE2B7 100%);
	background-size: 200% 200%;
	/* animation: gradient 13s ease infinite; */
	animation: gradient 8s ease infinite;
	height: 100vh;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}